<template>
  <div class="center-cmp">
    <div id="fujianEChart" style="width: 100%; height: 100%"></div>
    <dv-decoration-1 class="left-dv-decoration" style="width: 200px; height: 50px; position: absolute" />
    <dv-decoration-1 class="right-dv-decoration" style="width: 200px; height: 50px; position: absolute; right: 15px" />
  </div>
</template>

<script>
import fujianJson from './json/fujian.json'
import * as echarts from 'echarts'
import fujianMap from './fujianmap'

var fujainEChart
var count = 0
var time = 3000
var timer = null
var lastMove = null
var lockTime = null
export default {
  name: 'CenterMap',
  props: {
    warehouseList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {},
  data() {
    return {
      details: true,
      zoom: 0,
      popupPostion: { top: '60px', left: '100px', display: 'none' },
      inMap: false,
      modalData: {},
      targetId: 0,
      config: {
        data: [
          {
            name: '收费站',
            value: 1315
          },
          {
            name: '监控中心',
            value: 415
          },
          {
            name: '道路外场',
            value: 90
          },
          {
            name: '其他',
            value: 317
          }
        ],
        color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
        lineWidth: 30,
        radius: '55%',
        activeRadius: '60%'
      },

      labelConfig: {
        data: ['收费站', '监控中心', '道路外场', '其他']
      },
      data2: [],
      isShowTip: false
    }
  },
  watch: {
    warehouseList() {
      this.init()
    }
  },
  mounted() { },
  methods: {
    init() {
      const that = this
      document
        .getElementById('fujianEChart')
        .removeAttribute('_echarts_instance_')
      fujainEChart = echarts.init(document.getElementById('fujianEChart'))
      // 初始化福建地图
      echarts.registerMap('fujian', fujianJson, {})
      that.data2 = fujianMap.initData(this.warehouseList)
      fujainEChart.setOption(fujianMap.options())
      fujainEChart.on('mousemove', function (target) {
        console.log('traget', target)
        if (
          target.componentType === 'series' &&
          target.data &&
          target.data.code
        ) {
          lastMove = new Date().getTime()
          if (that.targetId !== target.data.index) {
            that.targetId = target.data.index
            const itemData = that.data2.find((item) => {
              return item.id === target.data.index
            })
            that.$emit('updateRight', itemData)
          }
        }
      })
      window.onmouseover = function () {
        if (timer) {
          that.hideTip(true)
        }
        lastMove = new Date().getTime()
      }
      that.autoShowTip()
      that.checkAutoShowTip()
    },
    autoShowTip() {
      timer = clearTimeout(timer)
      this.showTip()
    },
    showTip() {
      lastMove = new Date().getTime()
      this.hideTip()
      const item = this.data2[count]
      fujainEChart.dispatchAction({
        type: 'highlight',
        seriesIndex: item.seriesIndex,
        dataIndex: item.dataIndex
      })
      count++
      if (count >= this.data2.length) {
        count = 0
      }
      this.$emit('updateRight', item)
      timer = setTimeout(this.showTip, time)
    },
    hideTip(clear) {
      if (count === 0) {
        const preItem = this.data2[this.data2.length - 1]
        fujainEChart.dispatchAction({
          type: 'downplay',
          seriesIndex: preItem.seriesIndex // serieIndex的索引值   可以触发多个
        })
      } else {
        const preItem = this.data2[count - 1]
        fujainEChart.dispatchAction({
          type: 'downplay',
          seriesIndex: preItem.seriesIndex // serieIndex的索引值   可以触发多个
        })
      }
      if (clear) {
        timer = clearTimeout(timer)
      }
    },
    checkAutoShowTip() {
      const that = this
      lockTime = 10000 // 10秒钟没动就锁定
      lastMove = new Date().getTime() // 最近一次移动时间
      window.setInterval(function () {
        // 每1秒钟检查一次。
        var now = new Date().getTime()
        // 如果超时了
        if (now - lastMove > lockTime) {
          that.autoShowTip()
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less">
.center-cmp {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;

  .cc-header {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
  }

  .cc-details {
    height: 120px;
    display: flex;
    justify-content: center;
    font-size: 32px;
    align-items: center;

    .card {
      background-color: rgba(4, 49, 128, 0.6);
      color: #08e5ff;
      height: 70px;
      width: 70px;
      font-size: 45px;
      font-weight: bold;
      line-height: 70px;
      text-align: center;
      margin: 10px;
    }
  }

  .cc-main-container {
    position: relative;
    flex: 1;
    display: flex;

    .ccmc-middle {
      width: 50%;
      height: 90%;

      .active-ring-name {
        font-size: 20px !important;
      }
    }

    .ccmc-left,
    .ccmc-right {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 24px;

      span {
        font-size: 40px;
        font-weight: bold;
      }

      .station-info {
        height: 80px;
        display: flex;
        align-items: center;
      }
    }

    .ccmc-left {
      align-items: flex-end;

      span {
        margin-left: 20px;
      }
    }

    .ccmc-right {
      align-items: flex-start;

      span {
        margin-right: 20px;
      }
    }
  }

  .label-tag {
    position: absolute;
    width: 500px;
    height: 30px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
